'use strict'
import _ from 'lodash'

let idSuffix = 0

/**
 * @param {{
 *  text: string,
 *  title: string,
 *  toastClass: string,
 *  titleClass: string,
 *  toastIconClass: string,
 *  icon: string
 * }} opts
 */
/**
 *
 * @param opts IToastParams
 * @param defaults
 */
export default function showToast(opts, defaults) {
    idSuffix++

    opts = consolidateOpts(opts, defaults)

    const strData = _.map(opts.data, (v, k) => `data-${k}="${v.toString()}"`)
                     .join(' ')

    const toastElem = createToastHtml(opts, strData)

    $('#toasts-container').prepend(toastElem)

    attachHandlers(toastElem, opts, idSuffix)
    toastElem.toast('show')
}

function consolidateOpts(opts, defaults) {
    const defaultBootstrapData = {
        autohide: false,
        animation: true,
    }

    if (typeof opts === 'string') {
        opts = {
            text: opts,
        }
    }

    opts = _.merge(defaults, opts)
    opts.toastBackgroundClass = opts.toastBackgroundClass || ''
    opts.data = opts.data || defaultBootstrapData
    return opts
}

function attachHandlers(toastElem, opts, idSuffix) {
    toastElem.on('hidden.bs.toast', function() {
        $(this).remove()
    })

    if (isQuestion(opts)) {
        const primButton = `toast-questio-btn-prim-${idSuffix}`
        const secButton = `toast-questio-btn-sec-${idSuffix}`

        document.getElementById(primButton).addEventListener('click', opts.primaryActionCallback)
        document.getElementById(secButton).addEventListener('click', opts.secondaryActionCallback)
    }

}

function createToastHtml(opts, strData) {

    const buttons = createButtons(opts)

    const title = opts.title
        ? `<strong class="toast-title mb-2 d-flex align-items-center ${opts.titleClass}">${opts.title}</strong>`
        : ''

    const description = opts.text
        ? `<div class="toast-description mb-2 d-flex align-items-center mh-100 mw-100 overflow-hidden">${opts.text}</div>`
        : ''
    const noTitleClass = opts.title ? '' : 'no-title'

    const html = `        
            <div 
                class="toast ${opts.toastClass} fade hide ${noTitleClass} flex-grow-1"
                role="alert" 
                aria-live="assertive" 
                aria-atomic="true" 
                ${strData}>
                <div class="${opts.toastBackgroundClass} mw-100 d-flex p-3 flex-grow-1">
                    <div class="toast-icon toast-icon d-flex align-items-top">
                        <span>
                            <i class="material-icons">${opts.icon}</i>
                        </span>
                    </div>
    
                    <div class="toast-body flex-grow-1 mw-100 mh-100 overflow-hidden d-flex flex-column p-0 ml-3">
                        ${title}
                        ${description}
                        ${buttons}
                    </div>
                    
                    <div class="d-flex align-items-top">
                        <button type="button" class="close d-flex" data-dismiss="toast" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                </div>
            </div>
    `
    return $(html)
}

/**
 *
 * @param opts IToastParams
 * @returns {string}
 */
function createButtons(opts) {
    if (isQuestion(opts)) {
        return `
            <div class="mt-2">
                <button id="toast-questio-btn-prim-${idSuffix}" class="btn btn-primary btn-sm" data-dismiss="toast">
                    ${opts.primaryActionText}
                </button>
                <button id="toast-questio-btn-sec-${idSuffix}" class="btn btn-link btn-sm" data-dismiss="toast">
                    ${opts.secondaryActionText}
                </button>
            </div>`
    }

    return ''
}

function isQuestion(opts) {
    return opts.primaryActionText && opts.primaryActionCallback
}